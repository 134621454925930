<!--
 * @Author: wyq
 * @Date: 2021-10-28 10:27:11
 * @LastEditTime: 2021-11-01 16:59:04
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\trainingCenter\completedCard.vue
-->
<template>
  <div class="card rounded-xl">
    <div class="card-title">
      <div class="square color-warning">{{typeLabel}}</div>
      <span ref="katex1">{{card.topLabel}}</span>
      <v-icon color="#a8c1fc" @click="openDialog(card)">icon-zhuyi</v-icon>
    </div>
    <div ref="katex" class="card-content">{{card.label}}</div>
    <div class="card-footer">
      <div class="leftView">
        <p>{{card.allNum}}</p>
        <p>训练题数</p>
      </div>
      <div class="leftView">
        <p>{{getPercent(card.trueNum,card.allNum)}}</p>
        <p>正确率</p>
      </div>
      <div class="rightView" @click="previewTest(card.id)">
        <div>已完成试题</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    card: Object,
    typeLabel: String,
  },
  data() {
    return {
      show: false,
    }
  },
  watch: {
    'card.id': {
      handler() {
        setTimeout(() => {
          this.$formula(this.$refs['katex'])
          this.$formula(this.$refs['katex1'])
          setTimeout(() => {
            this.show = true
          }, 100)
        }, 100)
      },
      immediate: true,
    },
  },
  methods: {
    openDialog(val) {
      this.$emit('openDialog', val)
    },
    previewTest(id) {
      this.$emit('previewTest', id)
    },
    //计算正确率
    getPercent(correct, total) {
      if (correct == 0) {
        return '0%'
      } else {
        return Math.ceil((correct / total) * 100) + '%'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  display: inline-block;
  width: calc(50% - 20px);
  margin: 10px 0;
  padding: 20px 30px;
  background: #f2f6fc;
  border: 1px solid #d9e3fc;
  position: relative;

  &:nth-of-type(2n) {
    margin-right: 40px;
  }
  &-title {
    display: flex;
    align-items: center;
    color: #a8c1fc;
    .square {
      width: 40px;
      height: 26px;
      border-radius: 6px;
      text-align: center;
      font-size: 16px;
      border: 1px solid #a8c1fc;
      color: #a8c1fc;
    }
    span {
      flex: 1;
      padding: 0 16px;
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Medium;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
    }
  }
  &-content {
    margin: 10px 0;
    min-height: 78px;
    font-size: 16px;
    line-height: 26px;
    color: rgba(1, 72, 255, 0.3);
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .leftView {
      margin-right: 50px;
      font-family: Akrobat-Black;
      p {
        text-align: center;
        color: #999;
        font-size: 15px;
        margin-bottom: 0;
      }
      p:first-of-type {
        color: #333;
        font-size: 24px;
      }
    }
    .rightView {
      div {
        width: 100px;
        text-align: center;
        border: 1px solid #a8c1fc;
        border-radius: 5px;
        line-height: 35px;
        font-family: auto;
        color: #a8c1fc;
      }
    }
  }
}
</style>