<!--
 * @Author: yy
 * @Date: 2021-10-03 11:47:26
 * @LastEditTime: 2022-04-15 17:29:08
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\trainingCenter\record.vue
-->
<template>
  <el-container class="adaptive-testing">
    <el-header>
      <public-header :leftArrow="true">训练记录</public-header>
    </el-header>
    <el-main>
      <!-- <ul class="tab-bar">
        <li :class="sourceType==1?'active':''" @click="changeSourceType(1)">题型训练集</li>
        <li :class="sourceType==2?'active':''" @click="changeSourceType(2)">考点训练集</li>
      </ul> -->
      <loadMore ref="more" @query="queryList" :list.sync="list" defaultHeight="calc(100% - 1px)">
        <div v-for="(item, index) in list" :key="index" class="listView">
          <div class="dateTitle" v-if="item.day">{{getDay(item.day)}}</div>
          <template v-for="(card, cardIndex) in item.trains">
            <completed-card
              :card="card"
              :key="cardIndex"
              @openDialog="openDialog"
              @previewTest="previewTest"
              :typeLabel="sourceTypeLabel"
            ></completed-card>
          </template>
        </div>
        <div slot="noData">
          <img
            class="trainingCenter-noDataImg"
            src="@/assets/images/table/trainingCenter-noData.png"
          />
          <div class="trainingCenter-noDataTips">
            暂无训练记录
            <br />请先进行训练
          </div>
        </div>
      </loadMore>
      <knowledge-points-dialog :visible.sync="dialogVisible" :value="dialogValue"></knowledge-points-dialog>
    </el-main>
  </el-container>
</template>
<script>
import loadMore from '@/components/loadMore/loadMore2'
import knowledgePointsDialog from '@/components/dialogs/knowledgePointsDialog.vue'
import completedCard from '@/components/trainingCenter/completedCard'
export default {
  components: {
    loadMore,
    knowledgePointsDialog,
    completedCard,
  },
  data: () => ({
    list: [],
    dialogVisible: false,
    dialogValue: {},
    sourceType: 1, //题目来源类型 1 题型 2 知识点
  }),
  computed: {
    sourceTypeLabel() {
      return this.sourceType == 1 ? '题型' : '考点'
    },
  },
  methods: {
    queryList() {
      this.$service
        .getTrainingCenterList({
          trainStatus: 1, //0-未完成训练，1-已完成训练
          sourceType: this.sourceType,
        })
        .then((res) => {
          this.$refs.more.complete(res.timeTrains)
        })
    },
    //时间转换
    getDay(e) {
      var date = new Date(parseInt(e) * 1000)
      let Y = date.getFullYear() + '年'
      let M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '月'
      let D = date.getDate() + '日'
      return Y + M + D
    },
    openDialog(e) {
      this.dialogVisible = true
      this.dialogValue = e
    },
    previewTest(id) {
      this.$router.push({
        name: 'PreviewTesting',
        query: {
          type: 'finish',
          id,
        },
      })
    },
    changeSourceType(val) {
      this.sourceType = val
      this.$refs.more.reReload()
    },
  },
}
</script>
<style lang="scss" scoped>
.dateTitle {
  clear: both;
  font-size: 19px;
  line-height: 40px;
  color: #999;
}
.tab-bar {
  display: flex;
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
  border-radius: 24px;
  border: 1px solid #a8c1fc;
  overflow: hidden;
  li {
    display: block;
    flex: 1;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #a8c1fc;
    font-size: 16px;
    &.active {
      background: #a8c1fc;
      color: #fff;
    }
  }
}
</style>

