/*
 * @Author: wyq
 * @Date: 2021-07-27 15:53:46
 * @LastEditTime: 2021-09-26 10:26:42
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\utils\dialogMixin.js
 */
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
      submitLoadding: false
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val != this.dialogVisible) {
          this.dialogVisible = val
          this.submitLoadding = false
        }
      },
      immediate: true,
    },
    dialogVisible(val) {
      if (val != this.visible) {
        this.$emit('update:visible', val)
      }
    },
  },
  methods: {
    submit() {
      this.submitLoadding = true
      this.dialogVisible = false
      this.$emit('submit')
    },
    close() {
      this.dialogVisible = false
    },
  },
}