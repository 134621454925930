<!--
 * @Author: wyq
 * @Date: 2021-07-26 10:09:35
 * @LastEditTime: 2021-10-14 10:42:54
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student - 副本\src\components\dialogs\dialogTemplate.vue
-->
<template>
  <div class="overlay" v-if="dialogVisible" @click="close">
    <v-btn class="btn-close" icon color="#fff" @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="dialog-template" @click.stop>
      <v-card light class="rounded-lg">
        <div class="header">
          <slot name="title"></slot>
          <div class="header-title">{{title}}</div>
        </div>
        <div class="main">
          <slot></slot>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import mixin from '@/utils/dialogMixin'
export default {
  mixins: [mixin],
  props: {
    imgUrl: String,
    title: String,
  },
}
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 999;
}
.btn-close {
  position: absolute;
  top: 40px;
  right: 40px;
}
.dialog-template {
  width: 80vw;
  height: 80vh;
  padding: 12px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  border: 2px solid #ffffff;
  font-family: PingFangSC-Semibold, PingFang SC;
  .v-card {
    height: 100%;
  }
  .header {
    position: relative;
    padding: 26px 32px;
    background-color: #0148ff;
    overflow: hidden;
    font-size: 22px;
    color: #ffffff;
    &-title {
      position: absolute;
      right: 32px;
      bottom: -24px;
      font-size: 62px;
      color: rgba(255, 255, 255, 0.2);
    }
  }
  .main {
    height: calc(100% - 95px);
    padding: 32px;
    box-sizing: border-box;
    text-align: center;
    overflow: auto;
    text-align: left;
  }
}
</style>