<!--
 * @Author: wyq
 * @Date: 2021-09-26 14:35:45
 * @LastEditTime: 2021-11-15 11:17:32
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\knowledgePointsDialog.vue
-->
<template>
  <dialog-template
    :key="value.id"
    class="knowledge-points-dialog"
    :visible.sync="dialogVisible"
    title="知识点详解"
  >
    <div ref="katex">
      <div class="content-title">{{value.label}}</div>
      <div v-if="value.description" class="content-main">{{value.description}}</div>
    </div>
    <div class="image-container">
      <div v-for="item in value.images" :key="item.url" class="image">
        <img :src="item.url" />
        <p>{{item.name}}{{index}}</p>
      </div>
    </div>
  </dialog-template>
</template>
<script>
import dialogTemplate from './dialogTemplate'
import mixin from '@/utils/dialogMixin'
export default {
  components: {
    dialogTemplate,
  },
  mixins: [mixin],
  props: {
    value: Object,
  },
  watch: {
    visible() {
      setTimeout(() => {
        this.$formula(this.$refs['katex'])
      }, 100)
    },
  },
}
</script>
<style lang="scss" scoped>
.knowledge-points-dialog {
  ::v-deep .main {
    height: calc(100% - 52px);
  }
  .content-title {
    position: relative;
    margin-bottom: 52px;
    font-size: 22px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    &::after {
      position: absolute;
      left: 0;
      bottom: -26px;
      content: '';
      width: 27px;
      height: 4px;
      background: #0148ff;
      border-radius: 2px;
    }
  }
  .content-main {
    padding: 20px 28px;
    font-size: 16px;
    color: #999999;
    line-height: 26px;
    border: 1px dashed rgba(#0148ff, 0.1);
    white-space: pre-wrap;
  }
  .image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image {
    margin: 10px 0;
    width: 22.5%;
    // max-height: 420px;
    img {
      width: 100%;
      height: auto;
    }
    p {
      margin: 0;
      text-align: center;
    }
    & + .image {
      margin-left: 20px;
    }
  }
}
</style>